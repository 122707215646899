import { manageLastSectionBlack } from "./manageLastSectionBlack";
import { increaseZIndexForSections } from "./sectionCounter";

const sections = document.querySelectorAll("section");
const bodyClass = document.body.classList;
const careerApplyMain = document.querySelector("main.career-apply");

if (careerApplyMain) {
  // @ts-ignore
  return;
}
// classExist - array where script should be loaded on pages with specific body class names
const classExist = [
  "home",
  "single-industry",
  "single-career",
  "post-type-archive-podcast",
  "page-template-page-dynamic",
];

for (let i = 0; i < classExist.length; i++) {
  if (bodyClass.contains(classExist[i])) {
    document.addEventListener("scroll", () => {
      sections.forEach((section) => {
        if (!section.dataset.color) {
          return;
        }
        const colorClass = `bg-${section.dataset.color}`;
        section.classList.remove("bg-white", "bg-black", "bg-gray", "bg-green");
        section.classList.add(colorClass);
        const previousSibling = section.previousElementSibling;

        if (
          section.getBoundingClientRect().top <=
          (window.innerHeight || document.documentElement.clientHeight) / 2
        ) {
          if (previousSibling) {
            previousSibling.classList.remove(
              "bg-white",
              "bg-black",
              "bg-gray",
              "bg-green",
            );
            previousSibling.classList.add(colorClass);
          }
        }
      });
    });

    document.addEventListener("DOMContentLoaded", manageLastSectionBlack);

    // Call the function to execute the code
    increaseZIndexForSections();
  }
}
