function setCookiePopup(event, value) {
  const banner = document.getElementById("cookieBanner");
  const banner2 = document.getElementById("cookieBanner2");
  if (value == "next") {
    banner?.classList.add("hide-banner");
    banner2?.classList.remove("hide-banner");

    const animation = document.getElementById("animation");
    const declineBtn = document.getElementById(
      "cookieCancel",
    ) as HTMLButtonElement;
    declineBtn.disabled = true; // Disable the button
    animation?.classList.add("progress");

    // After 10 seconds, re-enable the button and hide the animation
    setTimeout(() => {
      declineBtn.disabled = false;
      declineBtn.classList.add("cookie-btn-cancel-hover");
    }, 10000);
  } else if (value == "true") {
    banner?.classList.add("hide-banner");
  }

  if (value == "previous") {
    banner2?.classList.add("hide-banner");
    banner?.classList.remove("hide-banner");
  }

  if (value == "true") {
    banner?.classList.add("hide-banner");
  } else if (value == "false") {
    banner2?.classList.add("hide-banner");
  }

  const expiryDate = new Date();
  expiryDate.setMonth(expiryDate.getMonth() + 1);
  const expires = "expires=" + expiryDate.toUTCString();

  document.cookie = `acceptedCookie=${value};${expires};path=/`;
}

window.setCookiePopup = setCookiePopup;
